<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script setup>
import {  watch,getCurrentInstance } from 'vue'
import {  useRouter } from 'vue-router'
//路由跳转
const router = useRouter()
//获取数据
// const route = useRoute()
const { proxy } = getCurrentInstance();
 
//监听当前路由
watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
  //获取所有路由
  var allroute = proxy.$router.options.routes
  console.log('oldValue:'+oldValue)
  console.log('newValue:'+newValue)
  console.log(allroute)
}, { immediate: true })
 

</script>
<style lang="stylus">
.font_11
  font-size: 11px
.font_12
  font-size: 12px
.font_14
  font-size: 14px
.font_16
  font-size: 16px
.font_18
  font-size: 18px
.font_28
  font-size: 18px
.font_48
  font-size: 48px
// .font_18
//   font-size: 18px
.mg_t_10
  margin-top: 10px
.mg_t_32
  margin-top: 32px
.mg_b_32
  margin-bottom: 32px
.mg_b_60
  margin-bottom: 60px
.mg_b_10
  margin-bottom: 10px
</style>
