import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import seamlessScroll from 'vue3-seamless-scroll'

const app = createApp(App)

app.use(ElementPlus)
app.use(router)
app.use(seamlessScroll )
app.mount('#app')

const debounce = (fn, delay) => {
let timer
    return (...args) => {
    if (timer) {
        clearTimeout(timer)
    }
    timer = setTimeout(() => {
        fn(...args)
    }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
    }
}
