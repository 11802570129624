// import  { RouteRecordRaw } from 'vue-router';
/**
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏、菜单搜索名称（国际化）
 *      hidden：        是否隐藏此路由
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 * }
 */

/**
 * 静态路由（默认路由）
 */
export const staticRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/index.vue'),
        meta: {
            hidden: true
        }
    },
    {
      path: '/facilitatorLogin',
      name: 'facilitatorLogin',
      component: () => import('@/views/facilitator/facilitatorLogin.vue'),
      meta: {
          hidden: true
      }
    },
    //   {
    //     path: '/404',
    //     name: '404',
    //     component: () => import('@/views/error/404.vue'),
    //     meta: {
    //       hidden: true
    //     }
    //   },
    {
      path: '/',
      component: () => import('@/layout/index.vue'),
      //重定向
      redirect: '/home',
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/index.vue'),
        },
        {
          path:"/SeparateAccount",
          name:"SeparateAccount",
          component: () => import('@/views/product/SeparateAccount.vue'),
        },
        {
          path:"/clearingCenter",
          name:"clearingCenter",
          component: () => import('@/views/product/clearingCenter.vue'),
        },
        {
          path:'/aggregatePay',
          name:'aggregatePay',
          component: () => import('@/views/product/aggregatePay.vue'),
        },
        {
          path:'/solution',
          name:"solution",
          component: () => import('@/views/solution/index.vue'),
          redirect:'/solution/ecommerceIndustry',
          children:[
            {
              path:'/solution/ecommerceIndustry',
              name:"ecommerceIndustry",
              component: () => import('@/views/solution/ecommerceIndustry.vue'),
              
            },
            {
              path:'/solution/sharingEconomy',
              name:"sharingEconomy",
              component: () => import('@/views/solution/sharingEconomy.vue'),
            },
            {
              path:'/solution/newRetail',
              name:"newRetail",
              component: () => import('@/views/solution/newRetail.vue'),
            },
            {
              path:'/solution/scenicSpot',
              name:"scenicSpot",
              component: () => import('@/views/solution/scenicSpot.vue'),
            },
            {
              path:'/solution/franchiseChain',
              name:"franchiseChain",
              component: () => import('@/views/solution/franchiseChain.vue'),
            },
            {
              path:'/solution/commercialComplex',
              name:"commercialComplex",
              component: () => import('@/views/solution/commercialComplex.vue'),
            },
            {
              path:'/solution/commonDomain',
              name:"commonDomain",
              component: () => import('@/views/solution/commonDomain.vue'),
              
            }
          ]
        },
        {
          path:"development",
          name:'development',
          component: () => import('@/views/development/development.vue'),
        },
        {
          path:"support",
          name:'support',
          component: () => import('@/views/support/support.vue'),
          
        },
        {
          path:"aboutWe",
          name:'aboutWe',
          component: () => import('@/views/aboutWe/aboutWe.vue'),
          
        },
      ]
    },
    // {
    //     path: '/',
    //     name: 'home',
    //     component: () => import('@/views/home/index.vue'),
    //     children: [

    //     ]
    // },


    /* 匹配任意的路由 必须最后注册 */
    //   { 
    //     path: '/:pathMatch(.*)', 
    //     name: 'Any',
    //     redirect: '/404', 
    //     meta: {
    //       hidden: true 
    //     }
    //   }
];


/**
 * 定义动态路由
 */
export const allAsyncRoutes = [];
